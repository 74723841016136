import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import GUI from "lil-gui";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
/**
 * Base
 */
// Debug
const gui = new GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// axesHelper
// const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTextures = [];

const notToAdd = [7];
for (let i = 1; i <= 8; i++) {
  if (notToAdd.includes(i)) continue;
  const matcapTexture = textureLoader.load(`/textures/matcaps/${i}.png`);
  matcapTexture.colorSpace = THREE.SRGBColorSpace;
  matcapTextures.push(matcapTexture);
}

/**
 * Materials
 */

const matcapMaterials = [];
for (let texture of matcapTextures) {
  matcapMaterials.push(new THREE.MeshMatcapMaterial({ matcap: texture }));

}


/**
 * Fonts
 */
const fontLoader = new FontLoader();
fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
  const textGeometry = new TextGeometry("Shmuel Toporowitch", {
    font: font,
    size: 0.5,
    height: 0.2,
    curveSegments: 20,
    bevelEnabled: true,
    bevelThickness: 0.1,
    bevelSize: 0.05,
    bevelOffset: 0,
    bevelSegments: 20,
  });

  // textGeometry.computeBoundingBox();
  // textGeometry.translate(
  //     -(textGeometry.boundingBox.max.x - 0.02) * 0.5,
  //     -(textGeometry.boundingBox.max.y - 0.02) * 0.5,
  //     -(textGeometry.boundingBox.max.z - 0.03) * 0.5
  // )

  textGeometry.center();

  const text = new THREE.Mesh(textGeometry, matcapMaterials[6]);
  scene.add(text);

  textGeometry.computeBoundingBox();
  const textBoundary = textGeometry.boundingBox;
  console.log("textBoundary", textBoundary);

  console.time("donuts");

  const dounatGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
  



  for (let i = 0; i < 400;) {
    const x = (Math.random() - 0.5) * 12;
    const y = (Math.random() - 0.5) * 12;
    const z = (Math.random() - 0.5) * 12;

    /*
    max _Vector3 {x: 3.219465732574463, y: 0.3797522783279419, z: 0.20000000298023224} 
    min _Vector3 {x: -3.219465732574463, y: -0.3797522783279419, z: -0.20000000298023224}
    */

    const extraSpace = 0.5;
    
    const inTextZone = {
      xMax: textBoundary.max.x + extraSpace,
      xMin: textBoundary.min.x - extraSpace,
      yMax: textBoundary.max.y + extraSpace,
      yMin: textBoundary.min.y - extraSpace,
      zMax: textBoundary.max.z + extraSpace,
      zMin: textBoundary.min.z - extraSpace,
    };

    const inText =
      x < inTextZone.xMax &&
      x > inTextZone.xMin &&
      y < inTextZone.yMax &&
      y > inTextZone.yMin &&
      z < inTextZone.zMax &&
      z > inTextZone.zMin;

    if(inText) {
        continue;
    }
      
    const dounat = new THREE.Mesh(
      dounatGeometry,
      matcapMaterials[Math.floor(Math.random() * matcapMaterials.length)]
    );



    // see dounta lines
    

    dounat.position.x = x;
    dounat.position.y = y;
    dounat.position.z = z;
    dounat.rotation.x = Math.random() * Math.PI;
    dounat.rotation.y = Math.random() * Math.PI;
    const scale = Math.random();
    dounat.scale.set(scale, scale, scale);

    i++;
    scene.add(dounat);
  }

  console.timeEnd("donuts");
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 2;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
